/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Link from '@mui/material/Link';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// import emailjs from '@emailjs/browser';

// Images
import bgImage from "assets/images/guatemala.jpg";
// import { AlertTitle } from "@mui/material";
const vqn1 = parseInt(Math.random() * 50,10);
const vqn2 = parseInt(Math.random() * 50,10);
const vqr = vqn1 + vqn2;  

function Contact() {
  const [clientMessage, setClientMessage] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [verificationField, setVerficiationField] = useState('');  
  const [sendResult, setSendResult] = useState('');   
  const verificationQuestion = `DESAFIO: Resultado de la suma ${vqn1} + ${vqn2}`;  

  const handleChange = event => {
    if (event.target.id === 'clientName')
      setClientName(event.target.value);
    else if (event.target.id === 'clientEmail')
      setClientEmail(event.target.value); 
    else if (event.target.id === 'clientPhone')
      setClientPhone(event.target.value);
    else if (event.target.id === 'clientMessage')
      setClientMessage(event.target.value);
    else if (event.target.id === 'verificationField')
    setVerficiationField(event.target.value);
  };

  const form = useRef();
  const sendForm = (e) => {    
    e.preventDefault();
    setSendResult('sending');
    if(clientName === '' || clientEmail === '' || clientPhone === '' || clientMessage === '' || verificationField === ''){
      setSendResult('error-fields');
      return;
    }
    if(parseInt(verificationField,10) === parseInt(vqr,10)){      
      emailjs.sendForm('service_z0hvlcc', 'template_w12gfkl', form.current, 'AYIJ8Z8Rr2XgZ14yG')
        .then(() => {
          setSendResult('success');
          setTimeout(() => { window.location.href='/' }, '2000');
        }, () => {
          setSendResult('error');          
        });
    }
    else{
      setSendResult('error');      
    }    
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Información de contacto
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Llena este formulario o contáctenos.
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        <Link href="tel:66466823" underline="always">+(502)6646-6823</Link>
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        <Link href="mailto:contacto@citymax-elite.com" underline="always">contacto@citymax-elite.com</Link>
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Km. 9.5 Antigua Carretera a El Salvador, Santa Catarina Pinula, Centro Comercial Vista Muxbal, local 132.
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                    <iframe title="Nuestra Ubicación" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.6951679569993!2d-90.47562468413301!3d14.559416581985468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a51b93a4fb07%3A0x1cc27ffc79633336!2sCityMax%20Elite!5e0!3m2!1ses-419!2sco!4v1666387138158!5m2!1ses-419!2sco" width="320" height="400" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
                    </MKBox>
                    <MKBox mt={3}>
                      <Link href="https://www.facebook.com/CityMax.Elite" target="_blank">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </Link>
                      <Link href="https://www.linkedin.com/company/citymaxelite/" target="_blank">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </Link>
                      <Link href="https://www.tiktok.com/@citymaxelite" target="_blank">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-tiktok" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </Link>
                      <Link href="https://www.instagram.com/citymax.elite/" target="_blank">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </Link>
                      <Link href="https://www.youtube.com/channel/UCwx6Fcbi798l1PZR1MPym_w" target="_blank">
                        <MKButton variant="text" color="white" size="large" iconOnly>
                          <i className="fab fa-youtube" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </Link>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      ¡Aquí estamos!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Escríbenos y nos pondremos en contacto muy pronto
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                  <form ref={form}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="clientName"
                          name="clientName"
                          onChange={handleChange}
                          value={clientName}
                          variant="standard"
                          label="Mi nombre"
                          placeholder="Nombre y Apellidos"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="clientPhone"
                          name="clientPhone"
                          onChange={handleChange}
                          value={clientPhone}
                          variant="standard"
                          label="Mi teléfono"
                          placeholder="Si estás en Guatemala no es necesario que incluyas el código del país."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="clientEmail"
                          name="clientEmail"
                          onChange={handleChange}
                          value={clientEmail}
                          variant="standard"
                          label="Mi correo electrónico"
                          placeholder="Dirección de correo electrónico."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="clientMessage"
                          name="clientMessage"
                          onChange={handleChange}
                          value={clientMessage}
                          variant="standard"
                          label="Mi requerimiento"
                          placeholder="Cuéntanos cuál es la propiedad de tus sueños..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="verificationField"
                          name="verificationField"
                          onChange={handleChange}
                          value={verificationField}
                          variant="standard"
                          label={verificationQuestion}
                          placeholder="Escribe aquí tu respuesta"
                          InputLabelProps={{ shrink: true }}
                          fullWidth                          
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                      {sendResult === 'error' && <Alert severity="error"><AlertTitle>Error</AlertTitle>No se ha podido enviar tu mensaje. Por favor verifica que todos los campos estén completados y que la respuesta a la pregunta de desafío sea la correcta.</Alert>}
                      {sendResult === 'error-fields' && <Alert severity="error"><AlertTitle>Error</AlertTitle>Para enviar el formulario es necesario completar todos los campos.</Alert>}
                      {sendResult === 'success' && <Alert severity="success"><AlertTitle>¡Gracias por 
                        contactarnos!</AlertTitle>Uno de nuestros ejecutivos asociados lo estará contactando muy pronto.</Alert>}                      
                      </Grid>

                    </Grid>
                    {sendResult !== 'sending' && sendResult !== 'success'  && <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant="gradient" color="info" onClick={sendForm}>
                        Enviar Mensaje
                      </MKButton>                      
                    </Grid>}

                    {sendResult === 'sending' &&  
                    <Grid item xs={12} pr={12} mb={12} textAlign="center">                      
                        <CircularProgress color="success" />
                    </Grid>
                    }
                    
                    </form>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
