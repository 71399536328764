/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/citymax-istp.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="CityMax Elite" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>              
              <MKTypography variant="h3" textAlign="center">CityMax Elite</MKTypography>              
              <MKTypography mt={3} variant="body1" fontWeight="light" color="text" textAlign="center">
              En <strong>CityMax Elite</strong> te ofrecemos asesoría para la compra, venta, renta y promoción de tu inmueble. Tenemos amplio inventario de inmuebles y un equipo de ejecutivos dispuestos a atenderte y hacer cumplir tu sueño de casa, Apartamentos, Locales Comerciales, Terrenos, Fincas y Bodegas. ¡Estamos listos para atenderte! contáctanos o bien deja tus datos para comunicarnos a la brevedad.            
              </MKTypography>
              <MKTypography  
                  textAlign="center"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{                                      
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  <Link href="https://www.citymax-elite.com/resultados-busqueda" target="_blank" rel="noopener">
                  Encuentra la propiedad de tus sueños <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </Link>
                </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
